$(window).on('load', function () {
  window.Geenie.Good = {
    cookie_name: 'is_good',
    /** @param {number} projectId */
    fetchLikedProjects(projectId) {
      const url = `${window.Env.getAPIBaseURL}/v2/liked-projects?project_ids[]=${projectId}`;
      return $.ajax({
        type: 'GET',
        url,
        cache: false,
        dataType: 'json',
        xhrFields: {
          withCredentials: true,
        },
      });
    },
    get_promise_update_regist(project_id, action_num) {
      return $.ajax({
        type: 'POST',
        url: '/api/good/regist',
        cache: false,
        dataType: 'json',
        data: {
          project_id: project_id,
          action: action_num,
          fuel_csrf_token: fuel_csrf_token(),
        },
      });
    },
    set_cookie(project_id, prelogin) {
      let goods_array = this.get_cookie()
        .split(',')
        .filter(function (v) {
          return !!v;
        });
      goods_array.push(project_id);
      goods_array = $.uniqueSort(goods_array);
      if (!prelogin && goods_array.length > 10) {
        return false;
      }
      if (goods_array.length > 11) {
        goods_array = goods_array.filter(function (v) {
          return v !== project_id;
        });
      }
      const d = new Date();
      d.setFullYear(d.getFullYear() + 5);
      $.cookie(this.cookie_name, goods_array.join(','), {
        expires: d, // 5 years
        path: '/',
        secure: true,
      });
      return true;
    },
    get_cookie() {
      return $.cookie(this.cookie_name) || '';
    },
    remove_cookie(project_id) {
      if (project_id) {
        let goods_array = this.get_cookie()
          .split(',')
          .filter(function (v) {
            return !!v && v !== String(project_id);
          });
        goods_array = $.uniqueSort(goods_array);
        const d = new Date();
        d.setFullYear(d.getFullYear() + 5);
        $.cookie(this.cookie_name, goods_array.join(','), {
          expires: d, // 5 years
          path: '/',
          secure: true,
        });
      } else {
        $.removeCookie(this.cookie_name);
      }
    },
    is_already(goods, target_project_id) {
      if (goods.length > 0) {
        const goods_array = goods.split(',');
        return goods_array.some(function (project_id) {
          return project_id === String(target_project_id);
        });
      }
      return false;
    },
  };
});
